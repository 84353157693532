<template>
  <div class="w-full h-full">
    <div class="w-full text-center text-zinc-400 tracking-widest uppercase text-xs mt-4">
      <p>Latest in the hood</p>
    </div>

    <div ref="wrapper" class="w-full">
      <!--<section @mouseenter="setMouseIn(true)" @mouseleave="setMouseIn(false)" @wheel.prevent ref="scrollerEl" class="w-full flex justify-start items-center overflow-x-auto overflow-y-hidden">
        <button class="h-[200px] aspect-video p-4 hover:scale-110 duration-300" v-for="(ann, index) in announcements" @click="selectAnn(index)" :aria-label="ann.title">
          <div class="w-full overflow-hidden h-full duration-500 bg-white/5 border border-white/10 rounded-xl flex justify-center items-center p-1">
            <NuxtImg v-if="!mounted" fit="cover" preload width="17.75" height="10" quality="1" :src="`${ann.cdn_banner}`" class="rounded-xl overflow-hidden w-full h-full blur-lg object-cover" :alt="`Banner of ${ann.title}`" />
            <NuxtImg v-else preload height="200" quality="70" :src="`${ann.cdn_banner}`" class="rounded-xl overflow-hidden w-full h-full object-cover" :alt="`Banner of ${ann.title}`" />
          </div>
        </button>
      </section>-->

      <Splide ref="splideEl" class="h-full md:w-auto w-full" :options="{ arrows: false, autoWidth: true, padding: 0, gap: 6, speed: 1000, pagination: false, type: 'loop', autoScroll: {
    speed: 0.5,
  } }" :extensions="{AutoScroll}">
            <SplideSlide @click="selectAnn(index)" v-for="(ann, index) in announcements" :key="ann" >
              <div  class="h-[200px] aspect-video p-4 hover:scale-110 duration-300">
          <div class="w-full overflow-hidden h-full duration-500 bg-white/5 border border-white/10 p-1 rounded-2xl">
            <!-- <img class="w-full h-full object-cover rounded-xl border border-white/10" :src="ann.banner" alt="" /> -->
            <NuxtImg preload height="200" quality="70" :src="`${ann.cdn_banner}`" class="rounded-xl overflow-hidden w-full h-full object-cover" :alt="`Banner of ${ann.title}`" />  
            
          </div>
        </div>
            </SplideSlide>
          </Splide>

      
        <ClientOnly>
          <HeadlessTransitionRoot as="template" :show="openInfo">
            <HeadlessDialog as="div" class="relative z-[1000]" @close="openInfo = false">
              <HeadlessTransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </HeadlessTransitionChild>

              <div class="fixed inset-0 z-[999999999999] overflow-y-auto">
                <div class="flex min-h-full items-center justify-center p-4 text-center">
                  <HeadlessTransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <HeadlessDialogPanel class="relative transform transition-all">
                      <div :class="['flex flex-col justify-center items-center', selectedAnn?.type == 'twitter' ? 'w-[50vh]' : '']">
                        <div class="w-full bg-woodsmoke-900 flex justify-between items-center p-2 rounded-md mb-2">
                          <div class="text-white uppercase font-bold tracking-wider flex justify-start items-center space-x-4">
                            <div>Read on Medium</div>
                            <div class="text-2xl">
                              <span class="iconify" data-icon="carbon:logo-medium"></span>
                            </div>
                          </div>

                          <a :href="selectedAnn?.mediumLink" target="_blank" class="btn btn-sm bg-turquoise-600 text-black hover:text-white">
                            <div>Open</div>
                          </a>
                        </div>

                        <div :class="['overflow-y-scroll max-h-[80vh] rounded-lg bg-woodsmoke-900 px-4 pt-5 pb-4 text-left shadow-xl w-full', selectedAnn?.type == 'twitter' ? 'flex justify-center sm:items-center items-start' : '']">
                          <article data-cy="annoucement-medium-post" v-if="selectedAnn?.type == 'medium'" class="center-resize prose w-full overflow-x-hidden" v-html="selectedAnn?.content"></article>

                          <div v-if="selectedAnn?.type == 'twitter'" v-html="selectedAnn?.content"></div>
                        </div>

                        <div class="w-full flex justify-center items-center mt-4">
                          <button data-cy="announcement-medium-close-button" @click="openInfo = false" class="btn w-auto px-8 text-lg bg-turquoise-600 border-0 text-black tracking-wider z-50">close</button>
                        </div>
                      </div>
                    </HeadlessDialogPanel>
                  </HeadlessTransitionChild>
                </div>
              </div>
            </HeadlessDialog>
          </HeadlessTransitionRoot>
        </ClientOnly>
      
    </div>

    <ClientOnly>
      <Teleport to="body">
        <div v-if="twitterConfig" class="w-screen h-screen flex flex-col fixed top-0 left-0 p-2 sm:p-8 bg-black/60 justify-center items-center backdrop-filter backdrop-blur-sm" style="z-index: 9999; display: fixed">
          <!-- <iframe class="w-full max-w-sm h-[600px] overflow-y-scroll sm:mb-8 mb-2" :data-tweet-url="layoutStore.twitterConfig.dataTweetUrl" :src="layoutStore.twitterConfig.src"></iframe> -->
          <iframe id="twitterIframe" class="w-[350px] h-[600px] overflow-y-scroll sm:mb-8 mb-2" :src="`https://twitframe.com/show?url=${encodeURIComponent(twitterConfig.dataTweetUrl)}`"></iframe>
          <div data-cy="annoucement-twitter-close" @click="twitterConfig = false" class="btn bg-turquoise-500 border-0 text-turquoise-1000 tracking-wider sm:font-black sm:text-lg hover:text-white mb-4">Close</div>
        </div>
      </Teleport>
    </ClientOnly>
  </div>
</template>

<script setup>
// import Flicking from "@egjs/vue3-flicking";
// import "@egjs/vue3-flicking/dist/flicking.css";
// import { AutoPlay } from "@egjs/flicking-plugins";

const splideEl = ref(null);
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

// const flickingOption = ref({ align: "center", circular: true, preventClickOnDrag: true, panelsPerView: -1});
// const plugins = [new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: true })];

const props = defineProps(["announcements"]);

const wrapper = ref(null);

// const scrollerEl = ref(null);

// import { useResizeObserver } from '@vueuse/core'

const { announcements } = toRefs(props);
// const flickingEl = ref(null);
// const flickingCurrentIndex = ref(0);

const openInfo = ref(false);
const selectedAnn = ref();
const twitterConfig = ref();

// const mounted = ref(false);
// const mouseIn = ref(false);

// const setMouseIn = (val) => {
//   mouseIn.value = val;
// };

// onNuxtReady(async () => {
//   // console.log("ANN");
//   // mounted.value = true;
// });

// import { useScreens } from "vue-screen-utils";
/*
const { mapCurrent } = useScreens();
const isMobile = mapCurrent({
  xs: 1,
  sm: 2,
  md: 2,
  lg: 3,
  xl: 3,
  xxl: 3,
});

flickingOption.value.panelsPerView = isMobile.value;

useResizeObserver(wrapper, (entries) => {
    // renderView()
flickingOption.value.panelsPerView = isMobile.value;
  console.log('resize')
  flickingEl.value.resize();
})
*/

// useResizeObserver(wrapper, (entries) => {
//     // renderView()
// // flickingOption.value.panelsPerView = isMobile.value;
// //   console.log('resize')
//   flickingEl.value.resize();
// })

// const updateFl = () => {
//   flickingCurrentIndex.value = flickingEl.value.index;
// };

const selectAnn = (id) => {
  // flickingEl.value.moveTo(id);

  // flickingCurrentIndex.value = id;
  selectedAnn.value = announcements.value[id];
  dataLayer.push({
    event: "click_open_announcement_modal",
    page: window.location.pathname,
    announcement_title: announcements.value[id].title,
    announcement_url: announcements.value[id].config.dataTweetUrl,
  });

  if (selectedAnn.value.type == "medium") {
    openInfo.value = true;
  }

  if (selectedAnn.value.type == "twitter") {
    // twitterConfig.value = selectedAnn.value.config;
    // layoutStore.invokeTwitterFrame(selectedAnn.value.config);
    window.open(selectedAnn.value.config.dataTweetUrl, "_blank");
  }
};

// import Lenis from "@studio-freight/lenis";

// onNuxtReady(async () => {
//   const lenis = new Lenis({
//     wrapper: scrollerEl.value,
//     orientation: "horizontal",
//     gestureOrientation: "horizontal",
//     infinite: true,
//   });

//   lenis.on("scroll", (e) => {
//     // console.log(e)
//   });

//   function raf(time) {
//     lenis.raf(time);
//     if (!mouseIn.value) {
//       lenis.scrollTo(lenis.scroll + 5);
//     }
//     requestAnimationFrame(raf);
//   }

//   requestAnimationFrame(raf);
// });
</script>
