<template>
  <div class="w-full h-full flex justify-center items-center relative">
    
    <NuxtImg quality="20" width="60" class="w-full h-full object-cover opacity-10 brightness-50 absolute" :src="project.project_banner" :alt="`${project.project_name} Banner`" />

    <div class="w-full grid grid-cols-4 h-full p-4 z-20 gap-4 justify-center items-center">

      <div class="w-full col-span-full flex justify-start items-center">
        <div class="p-2 bg-black text-turquoise-500 animate-pulse font-semibold rounded-md px-4 shadow-xl">
          {{ categoryLabel }}
        </div>
      </div>

      <div class="w-full aspect-square" >
        <NuxtImg quality="80" width="80" height="80" class="w-full h-full rounded-2xl border border-white/50" :src="project.project_logo" :alt="`${project.project_name} Logo`"/>
      </div>

      <div class="w-full col-span-3">
        <p class="text-white text-3xl font-semibold" >{{ project.project_name }}</p>
        <p class="text-sm text-woodsmoke-50" >{{ project.project_description }}</p>
      </div>

      <div class="col-span-full flex justify-end items-end" >
        <NuxtLink :to="`/app/project/${project.project_id}`" :aria-label="`Go to ${project.project_name} Project`">
          <button class="btn bg-turquoise-500/10 rounded-full border border-turquoise-500/50 text-turquoise-500">Go to Project</button>
        </NuxtLink>
      </div>

    </div>

  </div>
</template>

<script setup>

  const props = defineProps(["project","categoryLabel"]);

  const { project, categoryLabel } = toRefs(props);

</script>

<style>

</style>