<template>
  <div class="w-full h-full">
    <!-- <div class="w-full" >
      <div class="w-full h-full border border-zinc-800 rounded-xl">
      </div>
    </div> -->

    <div class="w-full h-full">
      <div class="w-full col-span-2">
        <div class="h-full relative">
          <!--<<!~~div v-if="address" :class="[loading ? 'h-10 w-full bg-woodsmoke-900/50 p-2 text-center' : 'h-0 w-full overflow-hidden']" class="duration-1000 flex justify-start items-center space-x-4 absolute top-0 left-0 skeleton rounded-none">
            <Icon name="eos-icons:loading" class="text-xl"></Icon>
            <p class="text-sm">Loading Projects</p>
          </div>~~>-->

          <div id="top" class="w-full border-b pt-8 border-b-white/10 from-transparent to-limed-spruce-900 bg-gradient-to-b min-h-[400px] flex flex-col justify-center items-center">
            <div class="pb-4 w-full">
              <HomeProfileCard></HomeProfileCard>
            </div>

            
              <HomeAnnouncement :announcements="announcements"></HomeAnnouncement>
            
          </div>

          <div class="w-full flex justify-between items-center p-2 sticky top-0 z-50 bg-black/50 backdrop-blur-sm">
            <button @click="preferenceStore.showCalendar = !preferenceStore.showCalendar" class="bg-white/5 rounded-md hover:bg-white/10 duration-200 ml-2 p-2 px-4">{{ preferenceStore.showCalendar ? "Hide" : "Show" }} Calendar</button>

            <div class="flex justify-end items-center space-x-2">
              <button @click="goToTop()" class="bg-white/5 hover:bg-white/10 duration-200 rounded-md p-2 px-4">Top</button>
              <button @click="goToAllProjects()" class="bg-white/5 hover:bg-white/10 duration-200 rounded-md p-2 px-4">All Projects</button>
            </div>
          </div>

          <div :class="[nuxtReady?'opacity-100':'opacity-20','duration-300']"  class="w-full p-4 flex justify-center items-center flex-col space-y-8">
            <div v-for="project in projects?.active?.projects" class="border flex-shrink-0 border-white/5 duration-300 bg-white/5 backdrop-blur-sm hover:bg-white hover:bg-opacity-[5%] w-full max-w-lg hover:shadow-2xl shadow-xl shadow-black/20 hover:border-white/50 rounded-xl aspect-video">
              <HomeBannerProject :categoryLabel="'Live Now'" :project="project"></HomeBannerProject>
            </div>

            <div v-for="project in projects?.upcoming?.projects" class="border flex-shrink-0 border-turquoise-500/20 duration-300 bg-white/5 backdrop-blur-sm hover:bg-white hover:bg-opacity-[5%] w-full max-w-lg hover:shadow-2xl shadow-xl shadow-black/20 hover:border-turquoise-500/50 rounded-xl aspect-video">
              <HomeBannerProject :categoryLabel="'Upcoming'" :project="project"></HomeBannerProject>
            </div>
          </div>

          <div class="w-full flex justify-center items-center px-4">
            <HomeBoard :posts="posts"></HomeBoard>
          </div>
        </div>
      </div>

      <div class="col-span-full pl-4" id="allProjects">
        <p class="px-6 py-8 text-turquoise-500 tracking-[0.3rem] uppercase">All Projects</p>
        <HomeTiles :projects="projects"></HomeTiles>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps(["projects", "posts", "announcements", "loading", "address"]);
const { projects, posts, announcements, loading, address } = toRefs(props);

import { usePreferenceStore } from "~/stores/preference";

const preferenceStore = usePreferenceStore();


const goToAllProjects = () => {

  const el = document.getElementById("allProjects");

  if (el) {
    el.scrollIntoView({ behavior: "smooth" });
  }

}

const goToTop = () => {
  const el = document.getElementById("top");

  if (el) {
    el.scrollIntoView({ behavior: "smooth" });
  }
}

const nuxtReady = ref(false);

onNuxtReady(async () => {
  nuxtReady.value = true;
})

</script>

<style scoped>
</style>
