<template>
  <div class="w-full h-full" >
    
    <HomeMobileLayout class="md:hidden"  :loading="loading" :address="$address" :projects="projectState" :posts="posts" :announcements="announcementState" ></HomeMobileLayout>
    <HomeDesktopLayout class="hidden md:block" :loading="loading"  :projects="projectState" :posts="posts" :announcements="announcementState" :address="$address" ></HomeDesktopLayout>


  </div>
</template>

<script setup>
definePageMeta({
  layout: "home",
  name: "app",
  middleware: ["app"]
});

useHead({
  title: "Oxbull | App",
  meta: [
    { name: "title", content: "Oxbull | App" },
    { name: "description", content: "Explore the latest blockchain collaborations on Oxbull.Tech. Our app offers exclusive access to stake, enroll, and understand tier policies in our carefully curated project selection." },
  ],
  htmlAttrs: { lang: "en" },
});

const projectState = useState('project')
const announcementState = useState('announcements')

// import { useUserStore } from "~/stores/user.js";
// const userStore = useUserStore();

// import { config } from "~/wagmiConfig.js";
// import { getAccount, watchConnections } from "@wagmi/core";

// import { HomeManager } from "~/components/Home/homeManager";

// import { useEventBus } from '@vueuse/core'
// const bus = useEventBus('reload')


// import { usePreferenceStore } from '~/stores/preference'

// const preferenceStore = usePreferenceStore()

// console.clear()
// console.log(bus)

// bus.on((ev) => {
//   // console.log(ev)
//   refreshHome()
//   // alert('hi')
// })


// import { useScreens } from "vue-screen-utils";

// const { mapCurrent } = useScreens();
// const isMobile = mapCurrent({
//   xs: true,
//   sm: false,
//   md: false,
//   lg: false,
//   xl: false,
//   xxl: false,
// });

// const address = ref("");
const loading = ref(false);

const { $address, $accessToken, $homeManager, $homePosts, $fetchProject, $fetchAnn } = useNuxtApp()

// $pullContents({
//   token: $accessToken.value,
//   address: $address.value
// })


// console.log("IND",$accessToken?.value, $address?.value)

// const unwatch = watchConnections(config, {
//   onChange(data) {
//     if (data) {
//       let _account = getAccount(config);
//       address.value = _account.address;
//     } else {
//       address.value = null;
//     }

//     
//   },
// });

// const unsub = userStore.$subscribe((state) => {
//   renderProjects();
// });

// onUnmounted(() => {
//   unwatch();
//   unsub();
// });


// const authState = () => {

//   // console.log("AUTH", userStore.authenticated)

//   return {
//     authenticated: $accessToken.value ? true : false,
//   };
// };

// const posts = ref([]);

// const desktopLayoutRef = ref(null);

// const renderPosts = () => {

//   let _project = projectState.value;

//   let posts = []

//   let _featuredPosts = []

//         if (_project?.featured?.projects?.length > 0) {
//           for (let project of _project.featured.projects) {
                  
//                   _featuredPosts.push({
//                       id: project.project_id,
//                       title: project.project_name,
//                       image: project.project_banner,
//                       logo: project.project_logo,
//                       description: project.project_description,
//                       ath: project.project_ath,
//                       tiers: project.project_tier_allocation.length > 0 ? project.project_tier_allocation[0] : [],
//                       type: "project",
//                   })
//               }
//         }

//         let _recentPosts = []



//         if (_project?.recent?.projects?.length > 0) {

//           for (let project of _project.recent.projects) {
                    
//                     _recentPosts.push({
//                         id: project.project_id,
//                         title: project.project_name,
//                         image: project.project_banner,
//                         logo: project.project_logo,
//                         description: project.project_description,
//                         ath: project.project_ath,
//                         tiers: project.project_tier_allocation.length > 0 ? project.project_tier_allocation[0] : [],
//                         type: "project",
//                     })
//                 } 
//         }

//       //   this.posts.push({
//       //       label: "Recent",
//       //       key: "recent",
//       //       posts: _recentPosts
//       //   })

//       //   this.posts.push({
//       //     label: "Featured",
//       //     key: "featured",
//       //     posts: _featuredPosts
//       // })

//       posts = [
//         {
//           label: "Recent",
//           key: "recent",
//           posts: _recentPosts
//         },
//         {
//           label: "Featured",
//           key: "featured",
//           posts: _featuredPosts
//         }
//       ]

//       // console.clear()
//       // console.log("POSTS", posts)

//       return posts;
// }

// const updateBoard = (args) => {

//   // console.log("update board", args)

  
//   projects.value = args?.projects;
//   posts.value = renderPosts(args?.projects);

//   loading.value = false;
//   desktopLayoutRef.value?.update();

//   // update the home cache

//   // console.log("update board", args)

//   if ( posts.value?.length > 0 && projects.value && $address.value) {
   
//     let _cachedHomeData = {
//       posts: posts.value,
//       projects: projects.value,
//       address: $address.value
//     }

//     // console.log("cached data", _cachedHomeData)
  
//     localStorage.setItem('cachedHomeData', JSON.stringify(_cachedHomeData))

//   }

// };

// const projects = ref(false);

// const postManager = ref(null);
// postManager.value = new HomeManager({
//   update: updateBoard,
//   authState: authState,
// });


// throttle the refresh

let _throttle = false;

// const useCachedContent = () => {
//   let _cachedHome = localStorage.getItem('cachedHomeData')
//   let _cachedData = JSON.parse(_cachedHome)

//   // console.log(_cachedData?.address, $address.value)

  
//   if(_cachedData?.address == $address.value){
//     // console.log(_cachedData)
//     updateBoard({
//       posts: _cachedData?.posts,
//       projects: _cachedData?.projects
//     })
//   }
// }

// const renderProjects = async () => {

//   // useCachedContent()


//   // if(!address.value){
//   //   let _account = getAccount(config);
//   //   address.value = _account.address;
//   // }

//   if (_throttle) {
//     clearTimeout(_throttle);
//   }

//   _throttle = setTimeout(() => {
//     refreshHome();
//   }, 300);

//   // refreshHome();
// };

const refreshHome =  () => {

  // loading.value = true;

  // $fetchProject()

  // get cached data

  // let _cachedHome = localStorage.getItem('cachedHomeData')
  
  // console.log(_cachedHome)

  // if(_cachedHome?.address == address.value){
  //   let _cachedData = JSON.parse(_cachedHome)

  //   // updateBoard({
  //   //   posts: _cachedData.posts,
  //   //   projects: _cachedData.projects
  //   // })

  // }
  
  // else{
  //   posts.value = [];
  //   projects.value = [];
  //   projects.value = false;
  // }

  // if(userStore?.cachedHomeData){
  //   let {posts,projects} = userStore.cachedHomeData
  //   posts.value = posts;
  //   projects.value = projects;
  // }

  

  // postManager.value.refresh();
};

let addressWatch, accessTokenWatch, homePostsWatch, homeProjectsWatch;

import { usePosthogStore } from "~/stores/posthogStore";
const posthogStore = usePosthogStore();

onMounted(() => {

  posthogStore.initPosthog();

  if($address.value){
    posthogStore.identifyUser($address.value, {
      address: $address.value
    });
  }

  // $fetchAnn()
  // renderProjects();
  // useCachedContent()

  // addressWatch = watch( $address, (oldValue, newValue) => {
  //   console.log("address changed", oldValue, newValue)
  //   // only render if new value is not null, and not the same as the old value

  //   if(newValue && oldValue != newValue){
  //     renderProjects();
  //   }

  //   // renderProjects();
  // })

  // accessTokenWatch = watch( $accessToken, (oldValue, newValue) => {
  //   console.log("access token changed", oldValue, newValue)
  //   // same, only render if new value is not null, and not the same as the old value

  //   if(newValue && oldValue != newValue){
  //     renderProjects();
  //   }
  //   // renderProjects();
  // })

  // homePostsWatch = watch( $homePosts, () => {
  //   updateBoard({
  //     posts: $homePosts.value,
  //     projects: projectState.value
  //   })
  // })

  // homeProjectsWatch = watch( projectState, () => {
  //   updateBoard({
  //     posts: $homePosts.value,
  //     projects: projectState.value
  //   })
  // })
  
});

const posts = computed( () => {

  let _project = projectState.value;
  let _posts = []

  let _featuredPosts = []

                        // console.log(_project?.featured)
        if (_project?.featured?.projects?.length > 0) {
          for (let project of _project.featured.projects) {
                  
                  _featuredPosts.push({
                      id: project.project_id,
                      title: project.project_name,
                      image: project.project_banner,
                      logo: project.project_logo,
                      description: project.project_description,
                      ath: project.project_ath,
                      tiers: project.project_tier_allocation,
                      type: "project",
                  })
              }
        }

        let _recentPosts = []



        if (_project?.recent?.projects?.length > 0) {

          for (let project of _project.recent.projects) {
                    
                    _recentPosts.push({
                        id: project.project_id,
                        title: project.project_name,
                        image: project.project_banner,
                        logo: project.project_logo,
                        description: project.project_description,
                        ath: project.project_ath,
                        tiers: project.project_tier_allocation,
                        type: "project",
                    })
                } 
        }

      //   this.posts.push({
      //       label: "Recent",
      //       key: "recent",
      //       posts: _recentPosts
      //   })

      //   this.posts.push({
      //     label: "Featured",
      //     key: "featured",
      //     posts: _featuredPosts
      // })

      _posts = [
        {
          label: "Recent",
          key: "recent",
          posts: _recentPosts
        },
        {
          label: "Featured",
          key: "featured",
          posts: _featuredPosts
        }
      ]

      // console.clear()
      // console.log("POSTS", posts)

      return _posts;
})

// onBeforeRouteLeave(() => {
//   addressWatch();
//   accessTokenWatch();
//   homePostsWatch();
//   homeProjectsWatch();
// });



</script>

<style scoped>

</style>
