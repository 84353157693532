<template>
  <div class="w-full flex justify-center items-center relative flex-col">
   
    <div class="w-full flex justify-center items-center pointer-events-auto relative">

      <div class="w-full max-w-lg flex justify-center items-center flex-col z-10 px-4">
        

        <div v-if="!userState" class="w-full flex justify-between items-center space-x-8 z-20 pointer-events-auto py-20">
          <div>
            <h2 class="text-3xl text-white mb-4">No Wallet Detected</h2>
            <h3>To enable all features in the website, please connect wallet.</h3>
          </div>

          <LazyWalletModal>
            <button class="btn bg-turquoise-500 text-turquoise-1000 border-none hover:text-white">Connect</button>
          </LazyWalletModal>
        </div>

        <div v-if="userState" class="w-full ">

          
          
          <div class="pt-4 w-full grid grid-cols-6 items-end gap">
            <div class="col-span-3">
              <p class="text-sm">Welcome Back</p>

              <div class="flex justify-start items-center space-x-2 pt-2">
                <img v-if="(computedTier?.tier > 0)" class="w-10 object-contain z-10" :src="`/tier${computedTier?.tier}.svg`" :alt="`${computedTier?.name} Badge`" />
                <p class="text-3xl text-white">{{ userState?.user_username ? userState?.user_username : 'No Username'  }}</p>
              </div>
            </div>

            <div class="col-span-3 text-right">
              <p class="text-sm">connected</p>
              <p class="text-white">{{ userState.user_address?.substring(0,10) }}...</p>
            </div>

            <div class="w-full col-span-full flex justify-end items-center pt-2">
              <button @click="$deauth()" class="btn btn-xs btn-link text-red-500 m-0 p-0 h-auto min-h-0 pointer-events-auto">disconnect</button>
            </div>


            

          </div>

          <div class="bg-white/5 backdrop-blur-sm w-full grid grid-cols-6 p-4 rounded-md mt-8 gap-x-8">
            <div class="col-span-3">
              <p class="text-xs">OXI Balance</p>
              <div class="w-full flex justify-between items-center">
                <p class="text-white">{{ Number(oxbBalance).toFixed(2) }} OXI</p>

                <NuxtLink :to="buy_oxb_url" target="_blank">
                <button class="btn btn-xs btn-link text-turquoise-500 m-0 p-0 h-auto min-h-0 pointer-events-auto">Buy</button>
              </NuxtLink>
              </div>
            </div>

            <div class="col-span-3">
              <p class="text-xs">OXI Staked</p>
              <div class="w-full flex justify-between items-center">
                <p class="text-white">{{ userState?.stakes?.amount > 0 ? Number(userState?.stakes?.amount).toFixed(2) : 0 }} OXI</p>
              

              <NuxtLink to="/app/stake">
                <button class="btn btn-xs btn-link text-turquoise-500 m-0 p-0 h-auto min-h-0 pointer-events-auto">Stake</button>
          </NuxtLink>
              </div>
            </div>
            
            
          </div>

          <template v-if="(!outerLoading && !loading)">
          
            <div v-if="!(computedTier?.tier > 0)" class="bg-white/5 backdrop-blur-sm w-full grid grid-cols-6 p-4 rounded-md mt-2">
              <div class="col-span-4">
                <p class="text-white" >No Tiers Found</p>
                <p class="text-woodsmoke-50">Stake to Join IDO!</p>
              </div>
  
              <NuxtLink class="col-span-2 w-full flex justify-end items-center" to="/app/stake">
              <button class="btn bg-turquoise-500 text-turquoise-1000 hover:text-white hover:ring hover:ring-turquoise-500 col-span-2 text-right pointer-events-auto">Stake Now</button>
                
                </NuxtLink>
            </div>
  
            <!--<div v-if="needLinkProfile" class="bg-white/5 backdrop-blur-sm w-full grid grid-cols-6 p-4 rounded-md mt-2">
              <div class="col-span-4">
                <p class="text-white" >Staking Disable</p>
                <p class="text-woodsmoke-50">Link Telegram to Enable</p>
              </div>
  
              <button @click="showBotProfile = true" class="btn text-turquoise-500 col-span-2 text-right pointer-events-auto">Link Now</button>
            </div>-->
            
          </template>


          <div class="modal p-0" :class="showBotProfile ? 'modal-open' : ''" role="dialog" aria-labelledby="linkTelegramTitle" aria-modal="true">
      <div class="w-auto  bg-woodsmoke-900 rounded-xl border border-white/20 max-h-[90vh] overflow-y-auto sm:ml-[250px] ">
        <div class="w-full flex justify-between items-center mb-4 border-b border-b-white/20 bg-woodsmoke-900/50 sticky top-0 backdrop-blur-lg p-4">
          <p class="text-xl  uppercase tracking-widest font-semibold" >Link Telegram</p>

          

          <button @click="showBotProfile = false" class="btn btn-sm text-red-500 border-red-500">close</button>
        </div>

        <div class="w-full">

          <ClientOnly >
            <LazyLinkBotProfile></LazyLinkBotProfile>
          </ClientOnly>

        </div>
      </div>
    </div>

        </div>

      </div>

      
    </div>


    

    
  </div>
</template>

<script setup>
// import { queryNoCache } from "~/apollo";
// import { gql } from "@apollo/client/core";

import { OXB_V2_ADDRESS } from "~/wagmiConfig.js";
import {  getBalance } from "@wagmi/core";

const { $deauth } = useNuxtApp()

const oxbBalance = ref(0);
const loading = ref(true);
const needLinkProfile = ref(false);

const showBotProfile = ref(false);
// const buy_oxb_url = "https://pancakeswap.finance/swap?outputCurrency=0x7536c00711E41df6aEBCCa650791107645b6bc52";
const buy_oxb_url = "https://app.uniswap.org/swap?outputCurrency=0x07fb80f7A8460DE3065d74B2663ba0D740fB3cA5&chain=base";

const props = defineProps(["loading"])
const {loading:outerLoading} = toRef(props, "loading");

const { $accessToken, $address, $wagmiConfig } = useNuxtApp()

const userState = useState('user')

defineExpose({
  loading,
});

const computedTier = computed( () => {

  if (userState.value?.tiers == "BSC_T1") {
    return {
      name: "Tier One",
      tier: 1,
    };
  } else if (userState.value?.tiers == "BSC_T2") {
    return {
      name: "Tier Two",
      tier: 2,
    };
  } else if (userState.value?.tiers == "BSC_T3") {
    return {
      name: "Tier Three",
      tier: 3,
    };
  }else {
    return {
      name: "No Tier",
      tier: 0,
    };
  }

})

    let fetchThrottle = false;
    
    const fetchOxbBalance = async () => {
      let _main = async () => {
        

    let balance = await getBalance($wagmiConfig, {
      address: $address.value,
      token: OXB_V2_ADDRESS,
      // chainId: 56
      chainId: 8453
    });

    // console.log(balance);

    oxbBalance.value = Number(balance.value) / 1e18;
  };

  if (fetchThrottle) {
    clearTimeout(fetchThrottle);
  }

  fetchThrottle = setTimeout(_main, 1000);

};

let throttle = false;

const renderCard = async () => {

  let main = async () => {
    loading.value = true;
    needLinkProfile.value = false;
    
    oxbBalance.value = 0;

    if ($accessToken.value) {
    
      if (userState.value?.user_telegram) {
        needLinkProfile.value = false;
      } else {
        needLinkProfile.value = true;
      }

      
    }

    if ($address.value) {
      await fetchOxbBalance();
    }

    

    loading.value = false;
  }

  if(throttle){
    clearTimeout(throttle)
  }

  throttle = setTimeout(main, 500)

};

onMounted(() => {
  renderCard();

  watch($address, () => {
    renderCard();
  });

  watch($accessToken, () => {
    renderCard();
  });

  watch(userState, () => {
    renderCard();
  });
});


</script>

<style scoped>
</style>
